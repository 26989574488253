<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Home Page Setting</h6>
        <button
          @click.prevent="update"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6>&nbsp;</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <input
                v-model.trim="data.title"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <textarea
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                rows="4"
              v-model.trim="data.value">
                   
                  </textarea
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { getDoc, getFirestore, serverTimestamp, doc, updateDoc  } from "firebase/firestore";

export default {
  data(){
    return{
      data:{},
      error:null
    }
  },
  mounted(){
    const docRef = doc(getFirestore(), "fix_parameter", "nAadjLTgQWSpSRDCOPbB");
    getDoc(docRef).then((snapshot)=>{
      this.data = snapshot.data()
    })
  },
  methods:{
    update(){
      if(this.data.title == null || this.data.title == '' || this.data.value == null || this.data.value == ''){
        this.error = "Harap isi Judul dan Deskripsi Halaman"
        alert(this.error)
      }else{
        const UpdateDoc = doc(getFirestore(), "fix_parameter", "nAadjLTgQWSpSRDCOPbB");
        this.data.updated_by = this.$store.state.DashboardStore.email
        this.data.updated_at = serverTimestamp()
        updateDoc(UpdateDoc, this.data);
        alert('Homepage Setting Successfully Updated')
      }
    }
  }
}
</script>
