<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <CardSocmed />
    </div>
  </div>
</template>
<script>
import CardSocmed from "@/components/Cards/Admin/SocialMedia/CardSocmed.vue";

export default {
  components: {
    CardSocmed,
  },
};
</script>
