<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Input Data Karya Desain</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Designer Name
              </label>
              <input
                v-model.trim="data.designer"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Student ID
              </label>
              <input
                v-model.trim="data.designer_id"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Semester
              </label>
              <select
                v-model="data.semester"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                    <option value="" disabled selected>NONE</option>
                    <option value="Ganjil">Ganjil</option>
                    <option value="Genap">Genap</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tags <button
                      class="
                        bg-emerald-500
                        text-white
                        active:bg-emerald-600
                        font-bold
                        uppercase
                        text-xs
                        px-4
                        py-2
                        rounded
                        shadow
                        hover:shadow-md
                        outline-none
                        focus:outline-none
                        mr-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      @click.prevent="AddTags"
                    >
                      Tambah Tags
                    </button>
              </label>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                School Year
              </label>
              <input
              type="number"
                v-model="data.school_year"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Subject
              </label>
              <select
                v-model="data.code"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                  <option v-for="(item, index) in subjectData" :key="index" :value=item.code>{{ item.name }}</option>
              </select>
            </div>
          </div>
          
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <!-- <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tags <button
                      class="
                        bg-emerald-500
                        text-white
                        active:bg-emerald-600
                        font-bold
                        uppercase
                        text-xs
                        px-4
                        py-2
                        rounded
                        shadow
                        hover:shadow-md
                        outline-none
                        focus:outline-none
                        mr-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      @click.prevent="AddTags"
                    >
                      Tambah Tags
                    </button>
              </label> -->
            </div>
            
          </div>         
          <div class="w-full lg:w-3/12 px-4" v-for="(tags, index) in tags" :key="index">
              <div class="relative w-full mb-3">
                <input
                v-model="tags.value"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              </div>
            </div>
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Design File
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadDesign"
                type="file"
                ref ="InputDesain"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*design image dimensions must be 4961x3508</i></p>
              </div>
              <img v-if="previewDesign" :src="previewDesign" width="300"><br/>

              <div class="relative w-full mb-3">
              <input @change="uploadDesign2"
                type="file"
                ref ="InputDesain2"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*design image dimensions must be 4961x3508</i></p>
              </div>
              <img v-if="previewDesign2" :src="previewDesign2" width="300"><br/>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Thumbnail
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadThumbnail"
                type="file"
                ref="InputThumbnail"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*thumbnail image dimensions must be 360x480</i></p>
              </div>
              <img v-if="previewThumbnail" :src="previewThumbnail" width="300"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Simpan"
        >
          Simpan
        </button>
    
        
    </div>
    
  </div>
  
</template>
<script>
import db from "@/firebase";
import { getFirestore,  serverTimestamp, addDoc, collection, onSnapshot, query } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
const storage = getStorage()

export default{
  data(){
    return{
      data:{
        designer: null,
        designer_id: null,
        semester: null,
        school_year: null,
        code: null,
        design:null,
        design2:null,
        thumbnail:null
      },
      subjectData: [],
      tags:[],
      previewDesign: null,
      previewDesign2: null,
      previewThumbnail: null,
      DesignData:null,
      DesignData2:null,
      ThumbnailData:null,
      submit:true,
      error: null
    }
  },
  created () {
    this.getSubject()
  },
  methods:{
    getSubject() {
      const q = query(collection(db, "course"));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
          const data = {
            name: doc.data().name,
            code: doc.data().code
          }
          this.subjectData.push(data)
        })
      })
    },
    AddTags(){
      this.tags.push({value:''})
    },
    Kembali(){
       this.$router.push('/admin/karyaDesain')
    },
    uploadDesign(event){
      this.DesignData = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.DesignData);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 4961 && height === 3508) {
          // Resolusi gambar valid
          if (this.DesignData.type != 'image/png' && this.DesignData.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.previewDesign = null
            this.data.design = null
            this.$refs.InputDesain.value = null
          }
          else {
            this.data.design = Date.now()+"_"+this.DesignData.name
            this.previewDesign = URL.createObjectURL(this.DesignData) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.DesignData = null
          return alert('Resolusi gambar tidak valid');
        }
      }
    },
    uploadDesign2(event){
      this.DesignData2 = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.DesignData2);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 4961 && height === 3508) {
          // Resolusi gambar valid
          if (this.DesignData2.type != 'image/png' && this.DesignData2.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.previewDesign2 = null
            this.data.design2 = null
            this.$refs.InputDesain2.value = null
          }
          else {
            this.data.design2 = Date.now()+"_"+this.DesignData2.name
            this.previewDesign2 = URL.createObjectURL(this.DesignData2) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.DesignData2 = null
          return alert('Resolusi gambar tidak valid');
        }
      }
    },
    uploadThumbnail(event){
      this.ThumbnailData = event.target.files[0]
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 360 && height === 480) {
          if (this.ThumbnailData.type != 'image/png' && this.ThumbnailData.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.previewThumbnail = null
            this.data.thumbnail = null
            this.$refs.InputThumbnail.value = null
          } else {
            this.data.thumbnail = Date.now()+"_"+this.ThumbnailData.name
            this.previewThumbnail = URL.createObjectURL(this.ThumbnailData) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.ThumbnailData = null
          return alert('Resolusi gambar tidak valid');
        }
      };
      img.src = URL.createObjectURL(this.ThumbnailData);
    },
    Simpan(){
      this.submit = true
      Object.values(this.data).forEach((value)=>{
        if(value == null || value == ''){
          this.submit = false
        }
      })
      if(this.submit){
        const storageDesignRef = ref(storage,"design/"+this.data.design)
        uploadBytes(storageDesignRef, this.DesignData)
        const storageDesignRef2 = ref(storage,"design/"+this.data.design2)
        uploadBytes(storageDesignRef2, this.DesignData2)
        const storageThumbnailRef = ref(storage,"thumbnail_design/"+this.data.thumbnail)
        uploadBytes(storageThumbnailRef, this.ThumbnailData)
        const db = getFirestore()
        const Ref = collection(db, "design_creation")
        this.data.created_at = serverTimestamp()
        this.data.created_by = this.$store.state.DashboardStore.email
        this.data.soft_delete = true
        this.data.tags = this.tags
        this.data.updated_at = serverTimestamp()
        this.data.updated_by = this.$store.state.DashboardStore.email
        addDoc (Ref, this.data)
        alert("Karya Desain Berhasil Disimpan")
        this.$router.push('/admin/karyaDesain')
      }else{
        this.error = []
        if (this.data.designer == null || this.data.designer == '') {
            this.error.push('\nDesigner Name')
        } 
        if (this.data.designer_id == null || this.data.designer_id == '') {
          this.error.push('\nStudent ID')
        } 
        if (this.data.semester == null || this.data.semester == '') {
          this.error.push('\nSemester')
        } 
        if (this.data.school_year == null || this.data.school_year == '') {
          this.error.push('\nSchool Year')
        } 
        if (this.data.code == null || this.data.code == '') {
          this.error.push('\nSubject')
        } 
        if (this.data.design == null || this.data.design == '') {
          this.error.push('\nDesign File 1')
        } 
        if (this.data.design2 == null || this.data.design2 == '') {
          this.error.push('\nDesign File 2')
        } 
        if (this.data.thumbnail == null || this.data.thumbnail == '') {
          this.error.push('\nThumbnail')
        }   
        alert('Harap isi kolom berikut :' + this.error)
      }
    }
  }
}
</script>