<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-blueGray-200
            border-0
          "
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">Input Credential</h6>
            </div>
            <p class="text-red-500" v-if="errorMessage">{{ this.errorMessage }}</p>
            <hr class="mt-2 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  v-model = "email"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  v-model = "password"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  placeholder="Password"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  @click.prevent="signin"
                  class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import github from "@/assets/img/github.svg";
import google from "@/assets/img/google.svg";
import { getAuth, signInWithEmailAndPassword} from "firebase/auth"

export default {
  data() {
    return {
      email:null,
      password:null,
      github,
      google,
      errorMessage:null
    };
  },
  methods:{
    signin(){
      this.errorMessage = null;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((user) => {
            const setToLocal = {
              email: this.email,
              isLogin: 1
            }

            localStorage.setItem('academic-exhibition', JSON.stringify(setToLocal))
            this.$store.state.DashboardStore.email = user.email
            this.$store.state.DashboardStore.loggedIn = true
            this.$router.push('/admin')
        })
        .catch((error) => {
            this.errorMessage = error.message === 'Firebase: Error (auth/user-not-found).' ? 'Invalid Email' : 'Invalid Password'
        });
    }
  }
};
</script>
