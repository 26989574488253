import { createStore } from 'vuex';
import MainStore from './MainStore';
import DashboardStore from './DashboardStore';

export default createStore({
  modules: {
    MainStore,
    DashboardStore,
  },
});
