<template>
  <div>
    <main>
      <section>
       <div class="container mx-auto px-auto" style="min-width:100%; min-height:100vh; display:flex; align-items:center; justify-content:center;">
          <div class="items-center flex flex-wrap" v-if="landingPage !== null">
            <div class="w-full hidden md:block md:w-6/12 px-0">
              <img v-if="isVideo == false" style="width: 100%; height: auto;" :src="landingPage.banner_image">
              <video v-if="isVideo" style="width: 100%; height: auto;" autoplay controls>
                <source :src="landingPage.banner_image" type="video/mp4">
              </video>
            </div>
            <div class="w-full md:w-6/12 px-0 py-0 mr-auto ml-auto">
              <div class="w-full md:w-12/12 justify-center flex flex-wrap">
                <div class="relative text-center flex flex-col mx-auto">
                  <img style="width: 75%; height: auto;" class="mx-auto mb-3" :src="landingPage.logo_image" :id="landingPage.logo_image">
                   <h1 class="font-sans text-4xl">
                    {{ landingPage.title }}
                  </h1>
                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500" style="width:336px;">
                    {{ landingPage.description }}
                  </p>
                  <a
                    href="/home"
                    class="
                      github-star
                      text-white
                      font-bold
                      px-6
                      py-4
                      outline-none
                      focus:outline-none
                      mx-auto
                      mb-4
                      bg-blueGray-700
                      active:bg-blueGray-600
                      text-sm
                      shadow
                      hover:shadow-lg
                      ease-linear
                      transition-all
                      duration-150
                    "
                    style="border-radius: 10rem;"
                  >
                      Enter Exhibition
                  </a>
                  <div class="flex flex-wrap justify-center">
                      <a
                        class="mx-4" style="width:1rem;" v-for="(item, index) in socmed" 
                        :key="index"
                        :href="item.url" target="_blank" 
                      >
                        <img
                          :id="item.icon"
                          :src="item.icon"
                        />
                      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
      </section>
    </main>
  </div>
</template>
<script>

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import db from "@/firebase";
import { getDocs, collection } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      landingPage: null,
      socmed: [],
      isVideo: false
    };
  },
  created () {
    this.getLanding()
    this.getSocmed()
  },
  methods: {
    async getBannerLanding(file){
      let bannerFile;
      const storage = getStorage()
      
      const fileExtension = file.split(".").pop();

      if (fileExtension === 'mp4' || fileExtension === 'mkv') {
        this.isVideo = true
         await getMetadata(ref(storage, 'landing_page/' + file)).then(async (metadata)=>{
          console.log(metadata);
          if (metadata.contentType == 'video/mp4') {
            this.isVideo = true
            bannerFile = await this.getImageFirebase(file)
          }
        })
      } else {
        this.isVideo = false
        bannerFile = await getDownloadURL(ref(storage, 'landing_page/' + file)).then((url)=>{
          return url
        })
      }

      return bannerFile
    },
    getLanding() {
      const query = getDocs(collection(db, "landing"))
      query.then((snapShots)=>{
        snapShots.docs.forEach(async (doc)=>{
          this.landingPage = {
            title: doc.data().title,
            description: doc.data().description,
            logo_image: await this.getImageFirebase(doc.data().logo_image),
            banner_image: await this.getBannerLanding(doc.data().banner_image)
          }
        })
      })
    },
    getSocmed() {
      const query = getDocs(collection(db, "social_media"))
      query.then((snapShots)=>{
        snapShots.docs.forEach(async (doc)=>{
          const data = {}
          data.url =  doc.data().url,
          data.icon = await this.getImageFirebase(doc.data().icon)
          this.socmed.push(data)
        })
      })
    },
    async getImageFirebase (image) {
      const storage = getStorage()

      const dataUrl = await getDownloadURL(ref(storage, 'landing_page/' + image)).then((url)=>{
        return url
      })

      return dataUrl
    }
  },
};
</script>