<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Input Data Landing Page</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Title
              </label>
              <input
                v-model="title"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <input
                v-model="description"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>      
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Banner Media
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadBanner"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*banner image dimensions must be 1200x1350</i></p>
              </div>
              <img v-if="previewBanner" :src="previewBanner" width="300"><br/>
              <video v-if="previewBanner" style="width: 100%; height: auto;">
                <source :src="previewBanner" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Logo Image
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadLogo"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*logo image dimensions must be 256x256</i></p>
              </div>
              <img v-if="previewLogo" :src="previewLogo" width="300"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Simpan"
        >
          Simpan
        </button>
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
const storage = getStorage()

export default{
  data(){
    return{
      title:null,
      description:null,
      previewBanner: null,
      previewLogo: null,
      bannerData:null,
      logoData:null,
      bannerName:null,
      logoName:null,
      isVideo: false,
      sumbit: true,
      error: null
    }
  },
  methods:{
    Kembali(){
       this.$router.push('/admin/landing')
    },
    uploadBanner(event){
      this.bannerData = event.target.files[0]
      const fileExtension = event.target.files[0].name.split(".").pop();
      if (fileExtension === 'mp4' || fileExtension === 'mkv') {
        this.isVideo = true
      } else {
        this.isVideo = false
        const img = new Image();
        img.src = URL.createObjectURL(this.bannerData);
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          if (width === 1200 && height === 1350) {
            // Resolusi gambar valid
            if (this.bannerData.type != 'image/png' && this.bannerData.type != 'image/jpeg' ) {
              alert('Format Gambar Harus jpg / jpeg / png')
              this.previewBanner = null
              this.bannerName = null
            }
            else {
              this.bannerName = Date.now()+"_"+this.bannerData.name
              this.previewBanner = URL.createObjectURL(this.bannerData)
            }
          } else {
            // Resolusi gambar tidak valid
            this.DesignData = null
            return alert('Resolusi gambar tidak valid');
          }
        }
      }
    },
    uploadLogo(event){
      this.logoData = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.logoData);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 256 && height === 256) {
          // Resolusi gambar valid
          if (this.logoData.type != 'image/png' && this.logoData.type != 'image/jpeg' ) {
            alert('Format Gambar Harus jpg / jpeg / png')
            this.logoName = null
            this.previewLogo = null
          }
          else {
            this.logoName = Date.now()+"_"+this.logoData.name
            this.previewLogo = URL.createObjectURL(this.logoData)
          }
        } else {
          // Resolusi gambar tidak valid
          this.logoData = null
          return alert('Resolusi gambar tidak valid');
        }
      }
    },
    Simpan(){
      this.sumbit = true
      if(this.title == null || this.title == '' || this.description == null || this.description == '' || this.bannerName == null || 
      this.bannerName == '' || this.logoName == null || this.logoName == '') {
        this.sumbit = false
      } 
      if (this.sumbit) {
        const storageBannerRef = ref(storage,"landing_page/"+this.bannerName)
        uploadBytes(storageBannerRef, this.bannerData)
        const storageLogoRef = ref(storage,"landing_page/"+this.logoName)
        uploadBytes(storageLogoRef, this.logoData)
        const db = getFirestore()
        const Ref = collection(db, "landing")
        const data = {
            created_at : serverTimestamp(),
            created_by : this.$store.state.DashboardStore.email,
            banner_image: this.bannerName,
            title : this.title,
            description : this.description,
            soft_delete: false,
            logo_image: this.logoName,
            updated_at : serverTimestamp(),
            updated_by : this.$store.state.DashboardStore.email,
        }
        addDoc (Ref, data);
        alert("Data Berhasil Disimpan")
        this.$router.push('/admin/landing')
      } else {
        this.error = []
        if (this.title == null || this.title == '') {
            this.error.push('\nTitle')
        } 
        if (this.description == null || this.description == '') {
          this.error.push('\nDescription')
        } 
        if (this.bannerName == null || this.bannerName == '') {
          this.error.push('\nBanner Media')
        }
        if (this.logoName == null || this.logoName == '') {
          this.error.push('\nLogo Image')
        }
        alert('Harap isi kolom berikut :' + this.error)
      }
    }
  }
}
</script>