<template>
  <div>
    <main>
    <index-navbar />
    <div>
      <section class="header relative pt-8 items-center max-h-860-px section-pad px-4 py-4">
        <div class="justify-center text-center flex flex-wrap md:mt-4">
          <div class="w-full md:w-6/12 px-12 md:px-4">
            <h2 class="font-semibold">{{ karya.designer }} - {{karya.designer_id}}</h2>
          </div>
        </div>
      </section>
      <section class="header relative items-center justify-center content-center flex content-section px-4 py-2">
            <img class="
                    top-0
                    b-auto
                    right-0
                    -mt-48
                    sm:mt-0
                  " 
                  style="margin-top:auto;"
                  :src="karya.design2" alt=""
                  :id="karya.design2">
      </section>
      <section class="header relative items-center flex content-section px-4 py-4">
            <img class="
                    top-0
                    b-auto
                    right-0
                    -mt-48
                    sm:mt-0
                  " 
                  style="margin-top:auto;"
                  :src="karya.design" alt=""
                  :id="karya.design">
      </section>
    </div>
      <section class="relative other-section">
        <div class="mx-4 font-semibold">
          YOU MAY ALSO LIKE
        </div>
          <div class="flex flex-wrap">
           <div class="w-6/12 xl:w-3/12 px-4 mt-10 mb-4" v-for="(item, index) in suggestion.slice(0,4)" :key="index">
              <!-- <router-link :to="'/content/'+item.designer_id"> -->
                <div class="container" v-if="index <= 3" @click="goToSuggestion(item.designer_id)">
                  <img :id="item.thumbnail" :src="URL(item.thumbnail)" alt="">
                  <div class="overlay overlay-mobile">
                    <div class="text-overlay text-mobile">
                      <h1>{{ item.designer }}</h1>
                      <h1>{{ item.designer_id }}</h1>
                    </div>
                  </div>
                </div>
              <!-- </router-link> -->
            </div>
          </div>
      </section>
    </main>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import db from "@/firebase";
import { collection, where, query, onSnapshot } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      content: this.$store.state.MainStore.content,
      suggestion: this.$store.state.MainStore.suggestion,
      karya: {}
    };
  },
  components: {
      IndexNavbar
  },
  created () {
    this.getKarya()
  },
  mounted() {
    this.getSuggestion()
  },
  methods: {
    goToSuggestion (id) {
      this.$router
          .push(`/content/${id}`)
          .then(() => { this.$router.go() })
    },
    async URL_Design(image){
      const storage = getStorage()
      const imageUrl = await getDownloadURL(ref(storage, 'design/'+image)).then((url)=>{
        return url
      })
      return imageUrl
    },
    URL(image){
      const storage = getStorage()
      getDownloadURL(ref(storage, 'thumbnail_design/'+image)).then((url)=>{
        const img = document.getElementById(image);
        img.setAttribute('src', url);
      })
    },
    getSuggestion() {
      const q = query(collection(db, "design_creation"));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
          const data = {}
          data.thumbnail = doc.data().thumbnail
          data.designer = doc.data().designer
          data.designer_id = doc.data().designer_id
          this.$store.commit('setSuggest',data)
        })
      })
    },
    getKarya () {
      const q = query(collection(db, "design_creation"), where("designer_id", "==", this.$route.params.student));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
          this.karya.design = await this.URL_Design(doc.data().design)
          this.karya.design2 = await this.URL_Design(doc.data().design2)
          this.karya.designer = doc.data().designer
          this.karya.designer_id = doc.data().designer_id
        })
      })
    }
  },
};
</script>
