import { createWebHistory, createRouter } from 'vue-router';

import Admin from '@/layouts/Admin.vue';
import Auth from '@/layouts/Auth.vue';
import Dashboard from '@/views/admin/Dashboard.vue';
import Settings from '@/views/admin/Settings.vue';
import Tables from '@/views/admin/Tables.vue';
import EditPassword from '@/views/admin/EditPassword.vue';
import AdminLanding from '@/views/admin/LandingPage/Index.vue';
import AddLanding from '@/views/admin/LandingPage/AddLanding.vue';
import EditLanding from '@/views/admin/LandingPage/EditLanding.vue';
import AdminSocmed from '@/views/admin/SocialMedia/Index.vue';
import EditSocmed from '@/views/admin/SocialMedia/EditSocmed.vue';
import AddSocmed from '@/views/admin/SocialMedia/AddSocmed.vue';
import MataKuliah from '@/views/admin/MataKuliah.vue';
import AddMataKuliah from '@/views/admin/AddMataKuliah.vue';
import EditMataKuliah from '@/views/admin/EditMataKuliah.vue';
import DetailMataKuliah from '@/views/admin/DetailMataKuliah.vue';
import DetailKaryaDesain from '@/views/admin/DetailKaryaDesain.vue';
import EditKaryaDesain from '@/views/admin/EditKaryaDesain.vue';
import KaryaDesain from '@/views/admin/KaryaDesain.vue';
import AddKaryaDesain from '@/views/admin/AddKaryaDesain.vue';
import Maps from '@/views/admin/Maps.vue';
import Login from '@/views/auth/Login.vue';
import Landing from '@/views/Landing.vue';
import Profile from '@/views/Profile.vue';
import Index from '@/views/Index.vue';
import Contact from '@/views/Contact.vue';
import Collection from '@/views/Collection.vue';
import Content from '@/views/Content.vue';
import store from '../store';

const routes = [
  {
    path: '/admin',
    redirect: '/admin/landing',
    component: Admin,
    beforeEnter: (to, from, next) => {
      const rs = localStorage.getItem('academic-exhibition')
      const auth = JSON.parse(rs)
      if (!auth) {
        next({ name: 'LoginPage' })
      } else {
        next()
      }
    },
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
      },
      {
        path: '/admin/settings',
        component: Settings,
      },
      {
        path: '/admin/tables',
        component: Tables,
      },
      {
        path: '/admin/landing',
        component: AdminLanding,
      },
      {
        path: '/admin/landing/add',
        component: AddLanding,
      },
      {
        path: '/admin/landing/edit/:id',
        component: EditLanding,
      },
      {
        path: '/admin/socmed',
        component: AdminSocmed,
      },
      {
        path: '/admin/socmed/edit/:id',
        component: EditSocmed,
      },
      {
        path: '/admin/socmed/add',
        component: AddSocmed,
      },
      {
        path: '/admin/mataKuliah',
        component: MataKuliah,
      },
      {
        path: '/admin/mataKuliah/add',
        component: AddMataKuliah,
      },
      {
        path: '/admin/editMatakuliah/:id',
        component: EditMataKuliah,
      },
      {
        path: '/admin/detailMatakuliah/:id',
        component: DetailMataKuliah,
      },
      {
        path: '/admin/karyaDesain',
        component: KaryaDesain,
      },
      {
        path: '/admin/karyaDesain/add',
        component: AddKaryaDesain,
      },
      {
        path: '/admin/detailKaryaDesain/:id',
        component: DetailKaryaDesain,
      },
      {
        path: '/admin/editKaryaDesain/:id',
        component: EditKaryaDesain,
      },
      {
        path: '/admin/maps',
        component: Maps,
      },
      {
        path: '/admin/profile',
        component: EditPassword,
      }
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: Auth,
    meta: { requiresUnauth: true },
    children: [
      {
        path: '/auth/login',
        component: Login,
        name: 'LoginPage'
      },
    ],
  },
  {
    path: '/home',
    component: Index,
  },
  {
    path: '/collection/:subject',
    component: Collection,
  },
  {
    path: '/content/:student',
    component: Content,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/contact',
    component: Contact,
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  const isAuth = store.getters.loggedIn

  if (requiresAuth && isAuth == false) {
    next('/auth')
  }else if (requiresUnauth && isAuth){
    next('/')
  } else next()
})

export default router;
