<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardAddMataKuliah />
    </div>
  </div>
</template>
<script>
import CardAddMataKuliah from "@/components/Cards/CardAddMataKuliah.vue";

export default {
  components: {
    CardAddMataKuliah,
  },
};
</script>
