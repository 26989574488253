<template>
  <div>
    <index-navbar />
    <section class="section-pad px-6">
      <!-- <div class="container mx-auto px-auto" style="min-width:100%"> -->
        <div class="items-center flex flex-wrap">
          <div class="w-full pb-4">
            <div class="w-full justify-center">
              <div class="text-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.982292488628!2d107.60849091487225!3d-6.892721069365898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e7f5500ac03b%3A0xd5c22f21fa2e77b2!2sFakultas%20Seni%20Rupa%20dan%20Desain%20ITB!5e0!3m2!1sen!2sid!4v1655136477107!5m2!1sen!2sid" width="100%" height="300rem" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
          <div class="w-full px-0">
            <div class="w-full flex flex-wrap justify-center">
              <div class="relative">
                <h2 class="font-sans text-center text-4xl">
                  Contact Us
                </h2>
                <p class="font-semibold text-base text-center leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Interior Design Undergraduate Program<br>Human & Interior Space Research Group<br>Institut Teknologi Bandung
                </p>
                <p class="text-base text-center leading-relaxed mb-4 text-blueGray-600">
                  CADL Building - ITB, 3rd floor<br>Jl. Ganesha 10, Bandung 40123<br>West Java - Bandung
                </p>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </section>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
    };
  },
  components: {
    IndexNavbar,
  },
};
</script>
