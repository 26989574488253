<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <!-- <HeaderStatsMataKuliah /> -->
      <CardMataKuliah />
    </div>
  </div>
</template>
<script>
import CardMataKuliah from "@/components/Cards/CardMataKuliah.vue";
// import HeaderStatsMataKuliah from "@/components/Headers/HeaderStatsMataKuliah.vue";

export default {
  components: {
    CardMataKuliah,
    // HeaderStatsMataKuliah
},
};
</script>
