import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyASGySaXLKXb3m-nzo82sBmeh5WZChb57I",
  authDomain: "academic-exhibition-itb.firebaseapp.com",
  projectId: "academic-exhibition-itb",
  storageBucket: "academic-exhibition-itb.appspot.com",
  messagingSenderId: "116089534121",
  appId: "1:116089534121:web:9a54b22d00c7b3445ddbb8",
  measurementId: "G-N4PK8WSN99"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;
