<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Detail Mata Kuliah</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Nama Kelas
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{name}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Code
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{code}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Tahun
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{year}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Deskripsi
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{desc}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Dibuat Oleh
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{created_by}} ({{created_at}})
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Diupdate Oleh
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{updated_by}} ({{updated_at}})
              </h6>
          </div>
           <div class="w-full lg:w-12/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                thumbnail
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <img v-if="previewImg" :src="previewImg" width="200"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
    
        
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, getDoc , doc, setDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
const storage = getStorage()
const db = getFirestore()

export default{
  data(){
    return{
      id:null,
      name: null,
      code: null,
      year: null,
      desc: null,
      previewImg: null,
      oldImage:null,
      ImageData:null,
      imageName:null,
      created_by:null,
      created_at:null,
      updated_by:null,
      updated_at:null
    }
  },
  computed:{
    // UpdateTanggalCreated(){
    //   const tanggal = this.created_at.date()
    //   console.log(tanggal)
    // }
  },  
  mounted(){
    this.id = this.$route.params.id
    const docRef = doc(db, "course", this.id)
    getDoc(docRef).then((docSnap)=>{
      this.name = docSnap.data().name
      this.code = docSnap.data().code
      this.year = docSnap.data().year
      this.desc = docSnap.data().desc
      this.created_by = docSnap.data().created_by
      this.created_at = docSnap.data().created_at.toDate()
      this.updated_by = docSnap.data().updated_by
      this.updated_at = docSnap.data().updated_at.toDate()
      this.oldImage = docSnap.data().thumbnail
      this.imageName = docSnap.data().thumbnail
      getDownloadURL(ref(storage, 'thumbnail/'+docSnap.data().thumbnail)).then((url)=>{
          this.previewImg = url
        })
    })
  },
  methods:{
    Kembali(){
       this.$router.push('/admin/mataKuliah')
    },
    upload(event){
        this.ImageData = event.target.files[0]
        this.imageName = this.ImageData.name
        this.previewImg = URL.createObjectURL(this.ImageData)
    },
    Simpan(){
      if(this.ImageData){
        const storageRef = ref(storage,"thumbnail/"+this.imageName)
        uploadBytes(storageRef, this.ImageData)
        deleteObject(ref(storage,"thumbnail/"+this.oldImage))
      }
      const Ref = doc(db, "course",this.id)
      const data = {
          code: this.code,
          created_by : this.$store.state.DashboardStore.email,
          name: this.name,
          thumbnail: this.imageName,
          updated_at : serverTimestamp(),
          updated_by : this.$store.state.DashboardStore.email,
          year: this.year
      }
      setDoc (Ref, data);
      alert("Mata Kuliah Berhasil Diupdate")
      this.$router.push('/admin/mataKuliah')
    }
  }
}
</script>