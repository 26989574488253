<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <HeaderStats />
      <card-line-chart />
    </div>
  </div>
</template>
<script>
import CardLineChart from "@/components/Cards/CardLineChart.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";

export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    HeaderStats
  },
};
</script>