<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Input Data Social Media</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                v-model="name"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                URL
              </label>
              <input
                v-model="url"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>      
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Icon
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadIcon"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <!-- <p style="font-size: 12px"><i>*banner image dimensions must be 1200x1350</i></p> -->
              </div>
              <img v-if="previewIcon" :src="previewIcon" width="300"><br/>
            </div>
          </div>
          <!-- <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Logo Image
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadLogo"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*logo image dimensions must be 256x256</i></p>
              </div>
              <img v-if="previewLogo" :src="previewLogo" width="300"><br/>
            </div>
          </div> -->
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="goBack"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="saveData"
        >
          Simpan
        </button>
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
const storage = getStorage()

export default{
  data(){
    return{
      name: null,
      url: null,
      previewIcon: null,
      iconData: null,
      iconName: null,
      sumbit: true,
      error: null,
    }
  },
  methods:{
    goBack(){
       this.$router.push('/admin/socmed')
    },
    uploadIcon(event){
      this.iconData = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.iconData);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width == height) {
          // Resolusi gambar valid
          if(this.iconData.type != 'image/png' && this.iconData.type != 'image/jpeg' ){
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.iconName = null
            this.previewIcon = null
          }else{
            this.iconName = Date.now()+"_"+this.iconData.name
            this.previewIcon = URL.createObjectURL(this.iconData)
          }
        } else {
          // Resolusi gambar tidak valid
          this.iconData = null
          return alert('Dimensi gambar harus persegi');
        }
      } 
    },
    saveData(){
      this.sumbit = true
      if(this.name == null || this.name == '' || this.url == null || this.url == '' || this.iconName == null || this.iconName == '') {
        this.sumbit = false
      } 
      if (this.sumbit) {
        const storageBannerRef = ref(storage,"landing_page/"+this.iconName)
        uploadBytes(storageBannerRef, this.iconData)
        const db = getFirestore()
        const Ref = collection(db, "social_media")
        const data = {
            created_at : serverTimestamp(),
            created_by : this.$store.state.DashboardStore.email,
            icon: this.iconName,
            name : this.name,
            url : this.url,
            soft_delete: false,
            updated_at : serverTimestamp(),
            updated_by : this.$store.state.DashboardStore.email,
        }
        addDoc (Ref, data);
        alert("Data Berhasil Disimpan")
        this.$router.push('/admin/socmed')
      } else {
        this.error = []
        if (this.name == null || this.name == '') {
            this.error.push('\nName')
        } 
        if (this.url == null || this.url == '') {
          this.error.push('\nURL')
        } 
        if (this.iconName == null || this.iconName == '') {
          this.error.push('\nIcon')
        }
        alert('Harap isi kolom berikut :' + this.error)
      }
    }
  }
}
</script>