<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Tambah Mata Kuliah</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Nama Mata Kuliah
              </label>
              <input
                v-model.trim="data.name"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Kode
              </label>
              <input
                v-model.trim="data.code"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tahun
              </label>
              <input
                type="number"
                v-model="data.year"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Deskripsi
              </label>
              <input
                v-model.trim="data.desc"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Thumbnail
              </label>
              <input @change="upload"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                ref="input"
              />
              <p style="font-size: 12px"><i>*thumbnail image dimensions must be 360x480</i></p>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <img v-if="previewImg" :src="previewImg" width="200"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Simpan"
        >
          Simpan
        </button>
    
        
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
const storage = getStorage()


export default{
  data(){
    return{
      data:{
        name: null,
        code: null,
        year: null,
        desc: null,
        thumbnail:null,
      },
      previewImg: null,
      ImageData:null,
      submit : true,
      error: null
    }
  },
  methods:{
    Kembali(){
       this.$router.push('/admin/mataKuliah')
    },
    upload(event){
      this.ImageData = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.ImageData);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 360 && height === 480) {
          // Resolusi gambar valid
          if(this.ImageData.type != 'image/png' && this.ImageData.type != 'image/jpeg' ){
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.previewImg = null
            this.data.thumbnail = null
            this.$refs.input.value = null
          } else {
            this.data.thumbnail = Date.now()+"_"+this.ImageData.name
            this.previewImg = URL.createObjectURL(this.ImageData) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.ImageData = null
          return alert('Resolusi gambar tidak valid');
        }
      } 
    },
    Simpan(){
      this.submit = true
      Object.values(this.data).forEach((value)=>{
        if(value == null || value == ''){
          this.submit = false
        }
      })
      if(this.submit){
        const storageRef = ref(storage,"thumbnail/"+this.data.thumbnail)
        uploadBytes(storageRef, this.ImageData)
        const db = getFirestore()
        const Ref = collection(db, "course")
        this.data.created_at = serverTimestamp(),
        this.data.created_by = this.$store.state.DashboardStore.email
        this.data.soft_delete = true
        this.data.updated_at = serverTimestamp(),
        this.data.updated_by = this.$store.state.DashboardStore.email
        console.log(this.data)
        addDoc (Ref, this.data)
        alert("Mata Kuliah Berhasil Disimpan")
        this.$router.push('/admin/mataKuliah')
      }else{
        this.error = []
        if (this.data.name == null || this.data.name == '') {
            this.error.push('\nNama Mata Kuliah')
        } 
        if (this.data.code == null || this.data.code == '') {
          this.error.push('\nKode')
        } 
        if (this.data.year == null || this.data.year == '') {
          this.error.push('\nTahun')
        } 
        if (this.data.desc == null || this.data.desc == '') {
          this.error.push('\nDeskripsi')
        } 
        if (this.data.thumbnail == null || this.data.thumbnail == '') {
          this.error.push('\nThumbnail')
        }   
        alert('Harap isi kolom berikut :' + this.error)
      }
    }
  }
}
</script>