<template>
  <div>
    <main>
    <index-navbar />
      <section class="header relative pt-8 items-center max-h-860-px section-pad">
        <div class="justify-center text-center flex flex-wrap md:mt-4">
          <div class="w-full md:w-6/12 px-12 md:px-4">
            <h2 class="font-semibold text-2xl home-title">{{ subject.title }}<br>{{ subject.code }}</h2>
            <p class="text-lg leading-relaxed mt-6 mb-8 text-blueGray-500 description">
              {{ subject.description }}
            </p>
          </div>
        </div>
      </section>
      <section class="section-pad collection-section">
          <form class="flex flex-wrap px-4">
            <div class="flex flex-wrap items-between w-6/12 lg:w-3/12 mb-4">
              <div class="w-full pr-4">
                <div class="relative flex flex-wrap items-center">
                  <label
                    class="uppercase text-blueGray-600 text-sm font-bold w-full lg:w-3/12"
                    htmlFor="grid-password"
                    style="display: inline-block;"
                  >
                    YEAR
                  </label>
                  <select @change="search()" v-model="year" name="" id="" class="w-full lg:w-8/12 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none focus:ring transition-all duration-150">
                    <option value="" selected>NONE</option>
                    <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap items-between w-6/12 lg:w-3/12 mb-4">
              <div class="w-full pl-4">
                <div class="relative flex flex-wrap items-center">
                  <label
                    class="uppercase text-blueGray-600 text-sm font-bold w-full lg:w-4/12"
                    htmlFor="grid-password"
                    style="display: inline-block;"
                  >
                    SEMESTER
                  </label>
                  <select @change="search()" v-model="semester" name="" id="" class="w-full lg:w-8/12 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none focus:ring transition-all duration-150">
                    <option value="" selected>NONE</option>
                    <option value="Ganjil">Ganjil</option>
                    <option value="Genap">Genap</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              class="flex flex-wrap items-center mb-4 lg:ml-auto w-full lg:w-4/12"
            >
              <!-- <div class="relative items-center flex flex-wrap"> -->
                <!-- <span
                  class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
                >
                  <i class="fas fa-search"></i>
                </span> -->
                <label
                  class="uppercase text-blueGray-600 text-sm font-bold w-full lg:w-3/12"
                  htmlFor="grid-password"
                  style="display: inline-block;"
                >
                  SEARCH
                </label>
                <input
                  @change="search()"
                  v-model="searchKey"
                  type="text"
                  placeholder="TITLE, DESIGNER, etc"
                  class="placeholder-blueGray-300 text-blueGray-600 text-sm shadow search-form"
                  style="width: 83%"
                />
                <button
                  class="
                    bg-white
                    text-blueGray-700
                    active:bg-blueGray-50
                    text-xs
                    font-bold
                    uppercase
                    ml-auto
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="button"
                  style="padding: 0.67rem 1rem; border-width: 1px; border-color: #000000;"
                  @click.prevent="search"
                >
                  <i class="fas fa-search"></i>
                </button>
              <!-- </div> -->
            </div>
          </form>
        <div class="flex flex-wrap" v-show="searchResult != null">
          <div class="w-6/12 xl:w-3/12 px-4 mt-6 mb-6" v-for="(item, index) in searchResult" :key="index">
            <router-link :to="'/content/'+item.designer_id">
              <div class="container">
                <img :id="item.thumbnail" :src="URL(item.thumbnail)" alt="">
                <div class="overlay overlay-mobile">
                  <div class="text-overlay text-mobile">
                    <h1>{{ item.designer }}</h1>
                    <h1>{{ item.designer_id }}</h1>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="flex flex-wrap" v-show="searchResult == null">
          <div class="w-6/12 xl:w-3/12 px-4 mt-6 mb-6" v-for="(item, index) in content" :key="index">
            <router-link :to="'/content/'+item.designer_id">
              <div class="container">
                <img :id="item.thumbnail" :src="URL(item.thumbnail)" alt="">
                <div class="overlay overlay-mobile">
                  <div class="text-overlay text-mobile">
                    <h1>{{ item.designer }}</h1>
                    <h1>{{ item.designer_id }}</h1>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import db from "@/firebase";
import { collection, where, query, onSnapshot } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      content: [],
      searchResult: null,
      years: null,
      semester: null,
      searchKey: null,
      subject: {
        title: null,
        code: null,
        description: null
      } 
    };
  },
  components: {
      IndexNavbar
  },
  mounted() {
    this.getContent()
    this.getSubject()
    this.getYears()
  },
  methods: {
    URL(image){
      const storage = getStorage()
      getDownloadURL(ref(storage, 'thumbnail_design/'+image)).then((url)=>{
        const img = document.getElementById(image);
        img.setAttribute('src', url);
      })
    },
    getSubject() {
      const q = query(collection(db, "course"), where("code", "==", this.$route.params.subject));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
          this.subject.title = doc.data().name
          this.subject.code = doc.data().code
          this.subject.description = doc.data().desc
        })
      })
    },
    getContent() {
      const q = query(collection(db, "design_creation"), where("code", "==", this.$route.params.subject));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
          const data = {
            thumbnail: doc.data().thumbnail,
            designer: doc.data().designer,
            designer_id: doc.data().designer_id,
            tags: doc.data().tags,
            school_year: doc.data().school_year,
            semester: doc.data().semester
          }
          this.content.push(data)
        })
      })
    },
    getYears() {
      var currentYear = new Date().getFullYear(), years = [];
      var startYear = 2020;  
      while ( startYear <= currentYear ) {
          years.push(startYear++);
      }
      
      this.years = years
    },
    search() {
      //not finished yet
      if (this.searchKey && this.year && this.semester) {
        const value = this.searchKey.toLowerCase()
        const filter = item => 
        // item.tags.some(tag => tag.toLowerCase().includes(value))
        item.designer_id.toLowerCase().includes(value) ||
        item.designer.toLowerCase().includes(value) &&
        item.school_year == this.year &&
        item.semester == this.semester

        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.searchKey && this.year) {
        console.log("search, year")
        const value = this.searchKey.toLowerCase()
        const filter = item => 
        item.designer_id.toLowerCase().includes(value) ||
        item.designer.toLowerCase().includes(value) &&
        item.school_year == this.year

        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.searchKey && this.semester) {
         console.log("search, semester")
        const value = this.searchKey.toLowerCase()
        const filter = item => 
        item.designer_id.toLowerCase().includes(value) ||
        item.designer.toLowerCase().includes(value) &&
        item.semester == this.semester

        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.year && this.semester) {
        console.log("year, semester")
        const filter = item => 
        item.school_year == this.year &&
        item.semester == this.semester

        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.searchKey) {
        console.log("seacrh")
        const value = this.searchKey.toLowerCase()
        const filter = item => 
        item.designer_id.toLowerCase().includes(value) ||
        item.designer.toLowerCase().includes(value)

        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.year) {
        console.log("year")
        const filter = item => 
        item.school_year == this.year
       
        const result = this.content.filter(filter)
      
        this.searchResult = result
      } else if (this.semester) {
        console.log("semester")
        const filter = item => 
        item.semester == this.semester

        const result = this.content.filter(filter)
      
        this.searchResult = result

      } else {
        this.searchResult = null
      }
    }
  },
};
</script>
