<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Detail Karya Desain</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Designer Name
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{designer}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Designer ID
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{designer_id}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Semester
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{semester}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                School Year
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{school_year}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Tags
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{tag}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Dibuat Oleh
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{created_by}} : {{created_at}}
              </h6>
          </div>
          <div class="w-full lg:w-3/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                Diupdate Oleh
              </h6>
          </div>
          <div class="w-full lg:w-9/12 px-4">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold ">
                : {{updated_by}} : {{updated_at}}
              </h6>
          </div>
          
           
        </div>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                design
              </h6>
              <img v-if="PreviewDesain" :src="PreviewDesain" width="300"><br/>
          </div>
          <div class="w-full lg:w-4/12 px-4">
              <h6 class="text-black-400 text-sm mt-3 mb-6 font-bold uppercase">
                thumbnail
              </h6>
              <img v-if="PreviewThumbnail" :src="PreviewThumbnail" width="300"><br/>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
        
      <button type="button"  class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          @click.prevent="Edit">Edit</button>
        
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore, getDoc , doc} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage()
const db = getFirestore()

export default{
  data(){
    return{
      id:null,
      designer: null,
      designer_id: null,
      thumbnail: null,
      design: null,
      link:null,
      semester:null,
      school_year:null,
      tag:'',
      created_by:null,
      created_at:null,
      updated_by:null,
      updated_at:null,
      PreviewDesain:null,
      PreviewThumbnail:null

    }
  },
  mounted(){
    this.id = this.$route.params.id
    const docRef = doc(db, "design_creation", this.id)
    getDoc(docRef).then((docSnap)=>{
      this.designer = docSnap.data().designer
      this.designer_id = docSnap.data().designer_id
      this.thumbnail = docSnap.data().thumbnail
      this.design = docSnap.data().design
      this.link = docSnap.data().link
      this.semester = docSnap.data().semester
      this.school_year = docSnap.data().school_year
      docSnap.data().tags.forEach((item)=>{
        this.tag += item.value+", "
      })
      this.created_by = docSnap.data().created_by
      this.created_at = docSnap.data().created_at.toDate()
      this.updated_by = docSnap.data().updated_by
      this.updated_at = docSnap.data().updated_at.toDate()
      this.oldImage = docSnap.data().thumbnail
      this.imageName = docSnap.data().thumbnail
      getDownloadURL(ref(storage, 'design/'+docSnap.data().design)).then((url)=>{
        this.PreviewDesain = url
      })
      getDownloadURL(ref(storage, 'thumbnail_design/'+docSnap.data().thumbnail)).then((url)=>{
        this.PreviewThumbnail = url
      })
    })
  },
  methods:{
    Kembali(){
       this.$router.push('/admin/karyaDesain')
    },
    Edit(){
       this.$router.push('/admin/editKaryaDesain/'+this.id)
    },
  }
}
</script>