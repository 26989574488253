<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardEditSocmed />
    </div>
  </div>
</template>
<script>
import CardEditSocmed from "@/components/Cards/Admin/SocialMedia/CardEditSocmed.vue";

export default {
  components: {
    CardEditSocmed,
  },
};
</script>
