<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardEditLanding />
    </div>
  </div>
</template>
<script>
import CardEditLanding from "@/components/Cards/Admin/LandingPage/CardEditLanding.vue";

export default {
  components: {
    CardEditLanding,
  },
};
</script>
