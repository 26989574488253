<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
    "
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="text-center flex justify-between">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Mata Kuliah 
          </h3>
          <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="addMataKuliah()"
        >
          Tambah Mata Kuliah
        </button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Nama Mata Kuliah
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Tahun
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Kode
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of $store.state.DashboardStore.mataKuliah" :key="index">
            <th
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                flex
                items-center
              "
            >
              <img :id="item.image"
                :src="URL(item.image)"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              />
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{item.name}}
              </span>
            </th>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{item.year}}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{item.code}}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-right
              "
            >
              <TableDropdownMataKuliah :thumbnail="item.image" :id = "item.id" :name = "item.name"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyASGySaXLKXb3m-nzo82sBmeh5WZChb57I",
  authDomain: "academic-exhibition-itb.firebaseapp.com",
  projectId: "academic-exhibition-itb",
  storageBucket: "academic-exhibition-itb.appspot.com",
  messagingSenderId: "116089534121",
  appId: "1:116089534121:web:9a54b22d00c7b3445ddbb8",
  measurementId: "G-N4PK8WSN99"
};

initializeApp(firebaseConfig);

import TableDropdownMataKuliah from "@/components/Dropdowns/TableDropdownMataKuliah.vue";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage()

import bootstrap from "@/assets/img/bootstrap.jpg";

export default {
  data() {
    return {
      bootstrap,
    };
  },
  mounted(){
    this.$store.state.DashboardStore.mataKuliah = []
    const db = getFirestore()
    const querySnapshot = collection(db, "course")
    getDocs(querySnapshot).then((snapshots)=>{
      snapshots.docs.forEach((doc)=>{
        const data = {}
        data.image = doc.data().thumbnail
        data.name = doc.data().name
        data.year = doc.data().year
        data.code = doc.data().code
        data.id = doc.id
        this.$store.commit('DataMataKuliah',data)
      })
    })
  },
  methods:{
    URL(image){
      getDownloadURL(ref(storage, 'thumbnail/'+image)).then((url)=>{
        const img = document.getElementById(image);
        img.setAttribute('src', url);

      })
    },
    addMataKuliah(){
      this.$router.push('/admin/mataKuliah/add')
    }
  },
  components: {
    TableDropdownMataKuliah,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
