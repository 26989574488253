<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardDetailKaryaDesain />
    </div>
  </div>
</template>
<script>
import CardDetailKaryaDesain from "@/components/Cards/CardDetailKaryaDesain.vue";

export default {
  components: {
    CardDetailKaryaDesain,
  },
};
</script>
