<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="
            w-12
            h-12
            text-sm text-white
            bg-blueGray-200
            inline-flex
            items-center
            justify-center
            rounded-full
          "
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a v-if="$store.state.DashboardStore.email"
        @click="profile()"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          is-clickable
        "
      >
        Change Password
      </a>
      
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="" @click.prevent="logout"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Log Out
      </a>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { createPopper } from "@popperjs/core";

import image from "@/assets/img/logo_itb_256.png";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },
  mounted(){
    const auth = getAuth();
    onAuthStateChanged(auth,(user)=>{
      if(user){
        this.$store.state.DashboardStore.email = user.email
      }
    })
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    logout(){
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push('/auth')
        this.$store.state.DashboardStore.email = null
        localStorage.removeItem('academic-exhibition');
      }).catch((error) => {
        console.log(error)
      });
      this.$store.state.email = null
    },
    profile () {
      this.$router.push('/admin/profile')
    }
  },
};
</script>

<style scoped>
.is-clickable {
  cursor: pointer;
}
</style>