<template>
  <nav
    class="
      top-0
      static
      z-50
      w-full
      flex flex-wrap
      items-center
      justify-between
      px-6
      py-4
      navbar-expand-lg
      bg-white
      section-pad
    "
  >
    <div
      class="w-full relative flex lg:w-auto lg:static lg:block justify-between"
    >
      <div
        class="lg:flex flex-grow items-center hidden lg:block"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <a
              class="
                hover:text-blueGray-500
                text-blueGray-700
                px-3
                py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              "
              href="/home"
            >
              HOME
            </a>
          </li>

          <li class="flex items-center">
            <a
              class="
                hover:text-blueGray-500
                text-blueGray-700
                px-3
                py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              "
              href="https://interiordesign.fsrd.itb.ac.id"
              target="_blank"
            >
              ACADEMIC
            </a>
          </li>

          <li class="flex items-center">
            <a
              class="
                hover:text-blueGray-500
                text-blueGray-700
                px-3
                py-2
                flex
                items-center
                text-xs
                uppercase
                font-bold
              "
              href="/contact"
            >
              CONTACT
            </a>
          </li>
        </ul>
      </div>
      <div class="flex items-center">
        <button
          class="
            cursor-pointer
            text-xl
            leading-none
            px-3
            py-1
            border border-solid border-transparent
            rounded
            bg-transparent
            block
            lg:hidden
            outline-none
            focus:outline-none
          "
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="flex items-center
              justify-center">
        <router-link to="/">
          <a
            class="
              text-blueGray-700
              font-bold
              leading-relaxed
              inline-block
              py-2
              whitespace-nowrap
              uppercase
              lg:hidden
              text-center
            "
            href="#pablo"
            style="font-size: 10px; margin: 0rem 0.5rem;"
          >
            <p>
              UNDERGRADUATE PROGRAM IN INTERIOR DESIGN <br />
              INSTITUT TEKNOLOGI BANDUNG
            </p>
          </a>
        </router-link>
      </div>
      <img class="lg:hidden" src="/logo_itb_256.png" alt="" style="max-width:20%"/>
    </div>
    <div
      class="lg:flex flex-grow items-center lg:hidden"
      :class="[navbarOpen ? 'block' : 'hidden']"
      id="example-navbar-warning"
    >
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <li class="flex items-center">
          <a
            class="
              hover:text-blueGray-500
              text-blueGray-700
              px-3
              py-2
              flex
              items-center
              text-xs
              uppercase
              font-bold
            "
            href="/"
          >
            HOME
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="
              hover:text-blueGray-500
              text-blueGray-700
              px-3
              py-2
              flex
              items-center
              text-xs
              uppercase
              font-bold
            "
            href="https://interiordesign.fsrd.itb.ac.id"
            target="_blank"
          >
            ACADEMIC
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="
              hover:text-blueGray-500
              text-blueGray-700
              px-3
              py-2
              flex
              items-center
              text-xs
              uppercase
              font-bold
            "
            href="/contact"
          >
            CONTACT
          </a>
        </li>
      </ul>
    </div>
    <div class="content-center text-center hidden lg:block" style="margin-right: 150px;">
      <router-link to="/">
        <a
          class="
            text-blueGray-700 text-sm
            font-bold
            leading-relaxed
            inline-block
            py-2
            whitespace-nowrap
            uppercase
          "
          href="#pablo"
        >
          <h3>
            UNDERGRADUATE PROGRAM IN INTERIOR DESIGN <br />
            INSTITUT TEKNOLOGI BANDUNG
          </h3>
        </a>
      </router-link>
    </div>
    <div class="hidden lg:block">
      <img class="h-20" src="/logo_itb_256.png" alt="" />
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {},
};
</script>