<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-32" style="padding-bottom: 50px;padding-top: 100px;">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <!-- <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-6/12 px-4">
            <card-stats
              statSubtitle="JUMLAH MATA KULIAH"
              :statTitle="jumlahMataKuliah"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-6/12 px-4">
            <card-stats
              statSubtitle="JUMLAH KARYA DESAIN"
              :statTitle="jumlahKaryaDesain"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import CardStats from "@/components/Cards/CardStats.vue";
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyASGySaXLKXb3m-nzo82sBmeh5WZChb57I",
  authDomain: "academic-exhibition-itb.firebaseapp.com",
  projectId: "academic-exhibition-itb",
  storageBucket: "academic-exhibition-itb.appspot.com",
  messagingSenderId: "116089534121",
  appId: "1:116089534121:web:9a54b22d00c7b3445ddbb8",
  measurementId: "G-N4PK8WSN99"
};

initializeApp(firebaseConfig);
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage()

export default {
  data() {
    return {
      jumlahMataKuliah: 0,
      jumlahKaryaDesain: 0
    }
  },

  mounted(){
    this.$store.state.DashboardStore.mataKuliah = []
    this.$store.state.DashboardStore.karyaDesain = []
    const db = getFirestore()
    const querySnapCourse = collection(db, "course")
    const querySnapDesignCreation = collection(db, "design_creation")
    getDocs(querySnapCourse).then((snapshots)=>{
      snapshots.docs.forEach((doc)=>{
        const data = {}
        data.id = doc.id
        this.$store.commit('DataMataKuliah',data)
      })
      this.jumlahMataKuliah = this.$store.state.DashboardStore.mataKuliah.length;
    })
    getDocs(querySnapDesignCreation).then((snapshots)=>{
      snapshots.docs.forEach((doc)=>{
        const data = {}
        data.id = doc.id
        this.$store.commit('DataKaryaDesain',data)
        // console.log(data)
      })
      this.jumlahKaryaDesain = this.$store.state.DashboardStore.karyaDesain.length;
    })
  },
  methods:{
    URL(image){
      getDownloadURL(ref(storage, 'thumbnail/'+image)).then((url)=>{
        const img = document.getElementById(image);
        img.setAttribute('src', url);

      })
    },
  },
  components: {
    // CardStats,
  },
};
</script>
