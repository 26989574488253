<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Ubah Password</h6>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="saveData"
        >
          Simpan
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Current Password
              </label>
              <input
                v-model="oldPassword"
                type="password"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                New Password
              </label>
              <input
                v-model="password"
                type="password"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Confrim New Password
              </label>
              <input
                v-model="confirmPass"
                type="password"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>      
        </div>
    </div>
    
  </div>
  
</template>
<script>
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth"

export default{
  data(){
    return{
      oldPassword: null,
      password: null,
      confirmPass: null,
      sumbit: true,
      error: null,
    }
  },
  methods:{
    saveData(){
      if (this.password == this.confirmPass) {
        const user = getAuth().currentUser
        const cred = EmailAuthProvider.credential(user.email, this.oldPassword)
        const newPassword = this.password // Replace with the new password
        
        reauthenticateWithCredential(user, cred)
        .then(() => {
            updatePassword(user, newPassword)
            .then(() => {
                // Password updated successfully
                alert("Password Berhasil Diubah")
                this.$router.push('/admin/landing')
            })
            .catch((error) => {
                // An error occurred while updating the password
                console.error(error);
            });
        })
        .catch((error) => {
            // An error occurred while updating the password
            alert('Masukan ulang password lama anda')
            console.error(error);
        })

      } else {
        alert('Confirm password does not match')
      }
    }
  }
}
</script>