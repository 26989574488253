<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Edit Data Landing Page</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Title
              </label>
              <input
                v-model="title"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <input
                v-model="description"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>      
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Logo Image
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadLogo"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*logo image dimensions must be 256x256</i></p>
              </div>
              <img v-if="logo_preview" :src="logo_preview" width="300"><br/>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Banner Media
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadBanner"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*banner image dimensions must be 1200x1350</i></p>
              </div>
              <video v-if="isVideo" style="width: 100%; height: auto;" controls>
                <source :src="banner_preview" type="video/mp4">
              </video>
              <img v-else :src="banner_preview" width="300"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="goBack"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="saveUpdate"
        >
          Update
        </button>
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, getDoc , doc, setDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
const storage = getStorage()
const db = getFirestore()

export default{
  data(){
    return{
      id:null,
      title: null,
      description: null,
      old_logo: null,
      new_logo: null,
      logo_preview: null,
      logo_name: null,
      old_banner: null,
      new_banner: null,
      banner_preview: null,
      banner_name: null,
      created_at: null,
      created_by: null,
      serverTimestamp: null,
      isVideo: false,
      sumbit: true,
      error: null
    }
  },
  mounted(){
    this.id = this.$route.params.id
    const docRef = doc(db, "landing", this.id)
    getDoc(docRef).then((docSnap)=>{
      this.title = docSnap.data().title
      this.description = docSnap.data().description
      this.banner_name = docSnap.data().banner_image
      this.logo_name = docSnap.data().logo_image
      this.created_at = docSnap.data().created_at
      this.created_by = docSnap.data().created_by
      this.serverTimestamp = serverTimestamp()
      this.old_banner = docSnap.data().banner_image
      this.old_logo = docSnap.data().logo_image
      getDownloadURL(ref(storage, 'landing_page/'+docSnap.data().banner_image)).then((url)=>{
        this.banner_preview = url
      })
      getDownloadURL(ref(storage, 'landing_page/'+docSnap.data().logo_image)).then((url)=>{
        this.logo_preview = url
      })
      const fileExtension = docSnap.data().banner_image.split(".").pop();

      if (fileExtension === 'mp4' || fileExtension === 'mkv') {
        this.isVideo = true
      } else {
        this.isVideo = false
      }
    })
  },
  methods:{
    goBack(){
       this.$router.push('/admin/landing')
    },
    uploadBanner(event){
      this.new_banner = event.target.files[0]
      const fileExtension = event.target.files[0].name.split(".").pop();
      if (fileExtension === 'mp4' || fileExtension === 'mkv') {
        this.isVideo = true
      } else {
        this.isVideo = false
        const img = new Image();
        img.src = URL.createObjectURL(this.new_banner);
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          if (width === 1200 && height === 1350) {
            // Resolusi gambar valid
            if (this.new_banner.type != 'image/png' && this.new_banner.type != 'image/jpeg' ) {
              alert('Format Gambar Harus jpg / jpeg / png')
              this.banner_name = null
              this.banner_preview = null
            }
            else {
              this.banner_name = Date.now()+"_"+this.new_banner.name
              this.banner_preview = URL.createObjectURL(this.new_banner)
            }
          } else {
            // Resolusi gambar tidak valid
            this.new_banner = null
            return alert('Resolusi gambar tidak valid');
          }
        }
      }
    },
    uploadLogo(event){
      this.new_logo = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.logoData);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width === 256 && height === 256) {
          // Resolusi gambar valid
          if (this.new_logo.type != 'image/png' && this.new_logo.type != 'image/jpeg' ) {
            alert('Format Gambar Harus jpg / jpeg / png')
            this.logo_name = null
            this.logo_preview = null
          }
          else {
            this.logo_name = Date.now()+"_"+this.new_logo.name
            this.logo_preview = URL.createObjectURL(this.new_logo)
          }
        } else {
          // Resolusi gambar tidak valid
          this.new_logo = null
          return alert('Resolusi gambar tidak valid');
        }
      }
    },
    saveUpdate(){
      this.sumbit = true
      if(this.title == null || this.title == '' || this.description == null || this.description == '') {
        this.sumbit = false
      } 
      if (this.sumbit) {
        if(this.new_banner){
          const storageRef = ref(storage,"landing_page/"+this.banner_name)
          uploadBytes(storageRef, this.new_banner)
          deleteObject(ref(storage,"landing_page/"+this.old_banner))
        }
        if(this.new_logo){
          const storageRef = ref(storage,"landing_page/"+this.logo_name)
          uploadBytes(storageRef, this.new_logo)
          deleteObject(ref(storage,"landing_page/"+this.old_logo))
        }
        const Ref = doc(db, "landing",this.id)
        const data = {
            created_at : this.created_at,
            created_by : this.created_by,
            title: this.title,
            description: this.description,
            banner_image: this.banner_name,
            logo_image: this.logo_name,
            soft_delete: false,
            updated_at : serverTimestamp(),
            updated_by : this.$store.state.DashboardStore.email,
        }
        console.log(data)
        setDoc (Ref, data);
        alert("Data Landing Berhasil Diupdate")
        this.$router.push('/admin/landing')
      } else {
        this.error = []
        if (this.title == null || this.title == '') {
            this.error.push('\nTitle')
        } 
        if (this.description == null || this.description == '') {
          this.error.push('\nDescription')
        } 
        alert('Harap isi kolom berikut :' + this.error)
      }
    },
  }
}
</script>