<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <!-- <HeaderStatsKaryaDesain /> -->
      <CardKaryaDesain />
    </div>
  </div>
</template>
<script>
import CardKaryaDesain from "@/components/Cards/CardKaryaDesain.vue";
// import HeaderStatsKaryaDesain from "../../components/Headers/HeaderStatsKaryaDesain.vue";

export default {
  components: {
    CardKaryaDesain,
    // HeaderStatsKaryaDesain
},
};
</script>
