<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardAddLanding />
    </div>
  </div>
</template>
<script>
import CardAddLanding from "@/components/Cards/Admin/LandingPage/CardAddLanding.vue";

export default {
  components: {
    CardAddLanding,
  },
};
</script>
