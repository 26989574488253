<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link :to="'/admin/detailMatakuliah/'+id"  class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        ">
        
       
        Detail
      </router-link>
      <router-link :to="'/admin/editMatakuliah/'+id"  class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        ">
        
       
        Edit
      </router-link>
      <a 
        @click.prevent="Delete"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Delete
      </a>
    </div>
  </div>
</template>
<script>
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyASGySaXLKXb3m-nzo82sBmeh5WZChb57I",
  authDomain: "academic-exhibition-itb.firebaseapp.com",
  projectId: "academic-exhibition-itb",
  storageBucket: "academic-exhibition-itb.appspot.com",
  messagingSenderId: "116089534121",
  appId: "1:116089534121:web:9a54b22d00c7b3445ddbb8",
  measurementId: "G-N4PK8WSN99"
};

initializeApp(firebaseConfig);
import { createPopper } from "@popperjs/core";
import { doc, deleteDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
const storage = getStorage()

export default {
  props:[
    'id',
    'name',
    'thumbnail'
  ],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    Delete(){
      if(confirm('Hapus Mata Kuliah "'+this.name+'"?')){
        deleteObject(ref(storage,"thumbnail/"+this.thumbnail))
        const db = getFirestore()
        deleteDoc(doc(db, "course", this.id));
        alert('Mata Kuliah "'+this.name+'" Berhasil Dihapus')
        const MataKuliahBaru = this.$store.state.DashboardStore.mataKuliah.filter(item=> item.id != this.id)
        this.$store.state.DashboardStore.mataKuliah =[]
        MataKuliahBaru.forEach((doc)=>{
          const data = {}
          data.name = doc.name
          data.year = doc.year
          data.code = doc.code
          data.id = doc.id
          this.$store.commit('DataMataKuliah',data)
        })
      }
      else{
        return false
      }

    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
