<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Edit Data Social Media</h6>
        
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                v-model="name"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                URL
              </label>
              <input
                v-model="url"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>      
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Icon Image
              </label>
              <div class="relative w-full mb-3">
              <input @change="uploadIcon"
                type="file"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <!-- <p style="font-size: 12px"><i>*logo image dimensions must be 256x256</i></p> -->
              </div>
              <img v-if="icon_preview" :src="icon_preview" width="300"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="goBack"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="saveUpdate"
        >
          Update
        </button>
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, getDoc , doc, setDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
const storage = getStorage()
const db = getFirestore()

export default{
  data(){
    return{
      id:null,
      name: null,
      url: null,
      old_icon: null,
      new_icon: null,
      icon_preview: null,
      icon_name: null,
      created_at: null,
      created_by: null,
      serverTimestamp: null,
      error: null
    }
  },
  mounted(){
    this.id = this.$route.params.id
    const docRef = doc(db, "social_media", this.id)
    getDoc(docRef).then((docSnap)=>{
      this.name = docSnap.data().name
      this.url = docSnap.data().url
      this.icon_name = docSnap.data().icon
      this.created_at = docSnap.data().created_at
      this.created_by = docSnap.data().created_by
      this.serverTimestamp = serverTimestamp()
      this.old_icon = docSnap.data().icon
      getDownloadURL(ref(storage, 'landing_page/'+docSnap.data().icon)).then((url)=>{
        this.icon_preview = url
      })
    })
  },
  methods:{
    goBack(){
       this.$router.push('/admin/socmed')
    },
    uploadIcon(event){
      this.new_icon = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.new_icon);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width == height) {
          // Resolusi gambar valid
          if(this.new_icon.type != 'image/png' && this.new_icon.type != 'image/jpeg' ){
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.icon_name = null
            this.icon_preview = null
          }else{
            this.icon_name = Date.now()+"_"+this.new_icon.name
            this.icon_preview = URL.createObjectURL(this.new_icon)
          }
        } else {
          // Resolusi gambar tidak valid
          this.new_icon = null
          return alert('Dimensi gambar harus persegi');
        }
      } 
    },
    saveUpdate(){
      this.sumbit = true
      if(this.name == null || this.name == '' || this.url == null || this.url == '') {
        this.sumbit = false
      } 
      if (this.sumbit) {
        if(this.new_icon){
          const storageRef = ref(storage,"landing_page/"+this.icon_name)
          uploadBytes(storageRef, this.new_icon)
          deleteObject(ref(storage,"landing_page/"+this.old_icon))
        }
        const Ref = doc(db, "social_media",this.id)
        const data = {
            created_at : this.created_at,
            created_by : this.created_by,
            name: this.name,
            url: this.url,
            icon: this.icon_name,
            soft_delete: false,
            updated_at : serverTimestamp(),
            updated_by : this.$store.state.DashboardStore.email,
        }
        console.log(data)
        setDoc (Ref, data);
        alert("Data Social Media Berhasil Diupdate")
        this.$router.push('/admin/socmed')
      } else {
        this.error = []
        if (this.name == null || this.name == '') {
            this.error.push('\nName')
        } 
        if (this.url == null || this.url == '') {
          this.error.push('\nURL')
        }
        alert('Harap isi kolom berikut :' + this.error)
      }
    },
  }
}
</script>