<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Edit Karya Desain</h6>
        
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Designer Name
              </label>
              <input
                v-model.trim="data.designer"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Student ID
              </label>
              <input
                v-model.trim="data.designer_id"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Semester
              </label>
              <select
                v-model="data.semester"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                    <option value="" disabled selected>NONE</option>
                    <option value="Ganjil">Ganjil</option>
                    <option value="Genap">Genap</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tags <button
                      class="
                        bg-emerald-500
                        text-white
                        active:bg-emerald-600
                        font-bold
                        uppercase
                        text-xs
                        px-4
                        py-2
                        rounded
                        shadow
                        hover:shadow-md
                        outline-none
                        focus:outline-none
                        mr-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      @click.prevent="AddTags"
                    >
                      Tambah Tags
                    </button>
              </label>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                School Year
              </label>
              <input
              type="number"
                v-model="data.school_year"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Subject
              </label>
              <select
                v-model="data.code"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                  <option v-for="(item, index) in subjectData" :key="index" :value=item.code>{{ item.name }}</option>
              </select>
            </div>
          </div>
          
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <!-- <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Tags <button
                      class="
                        bg-emerald-500
                        text-white
                        active:bg-emerald-600
                        font-bold
                        uppercase
                        text-xs
                        px-4
                        py-2
                        rounded
                        shadow
                        hover:shadow-md
                        outline-none
                        focus:outline-none
                        mr-1
                        ease-linear
                        transition-all
                        duration-150
                      "
                      type="button"
                      @click.prevent="AddTags"
                    >
                      Tambah Tags
                    </button>
              </label> -->
            </div>
            
          </div>         
          <div class="w-full lg:w-3/12 px-4" v-for="(tags, index) in data.tags" :key="index">
              <div class="relative w-full mb-3">
                <input
                v-model="tags.value"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              /><button @click.prevent="HapusTag(index)">Hapus</button>
              </div>
            </div>
        </div>
        <div class="flex flex-wrap">
           <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Design File
              </label>
              <div class="relative w-full mb-3">
              <input @change="UploadDesain"
                type="file"
                ref="InputDesain"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*design image dimensions must be 4961x3508</i></p>
              </div>
              <img v-if="PreviewDesign" :src="PreviewDesign" width="300"><br/>

              <div class="relative w-full mb-3">
              <input @change="UploadDesain2"
                type="file"
                ref="InputDesain2"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*design image dimensions must be 4961x3508</i></p>
              </div>
              <img v-if="PreviewDesign2" :src="PreviewDesign2" width="300"><br/>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Thumbnail
              </label>
              <div class="relative w-full mb-3">
              <input @change="UploadThumbnail"
                type="file"
                ref="InputThumbnail"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
              <p style="font-size: 12px"><i>*thumbnail image dimensions must be 360x480</i></p>
              </div>
              <img v-if="PreviewThumbnail" :src="PreviewThumbnail" width="300"><br/>
            </div>
          </div>
        </div>
    
      <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Kembali"
        >
          Kembali
        </button>
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-6
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click.prevent="Simpan"
        >
          Update
        </button>
    
        
    </div>
    
  </div>
  
</template>
<script>
import { getFirestore,  serverTimestamp, getDoc , doc, setDoc, onSnapshot, query, collection} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
const storage = getStorage()
const db = getFirestore()

export default{
  data(){
    return{
      data:{},
      id:null,
      DesignData:null,
      DesignData2:null,
      ThumbnailData:null,
      OldDesign:null,
      OldDesign2:null,
      OldThumbnail:null,
      PreviewDesign:null,
      PreviewDesign2:null,
      PreviewThumbnail:null,
      submit:true,
      subjectData: this.$store.state.MainStore.subject,
      error: null
    }
  },
  mounted(){
    this.id = this.$route.params.id
    const docRef = doc(db, "design_creation", this.id)
    getDoc(docRef).then((docSnap)=>{
      this.data = docSnap.data()
      this.OldDesign = docSnap.data().design
      this.OldDesign2 = docSnap.data().design2
      this.OldThumbnail = docSnap.data().thumbnail
      getDownloadURL(ref(storage, 'thumbnail_design/'+docSnap.data().thumbnail)).then((url)=>{
        this.PreviewThumbnail = url
      })
      getDownloadURL(ref(storage, 'design/'+docSnap.data().design)).then((url)=>{
        this.PreviewDesign = url
      })
      getDownloadURL(ref(storage, 'design/'+docSnap.data().design2)).then((url)=>{
        this.PreviewDesign2 = url
      })
    })
    this.getSubject()
  },
  methods:{
    getSubject() {
      const q = query(collection(db, "course"));
      onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
          const data = {}
          data.name = doc.data().name
          data.code = doc.data().code
          this.$store.commit('setSubject',data)
        })
      })
    },
    Kembali(){
       this.$router.push('/admin/detailKaryaDesain/'+this.id)
    },
    HapusTag(index){
       this.data.tags.splice(index,1)
    },
    UploadDesain(event){
      this.DesignData = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.DesignData);

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width === 4961 && height === 3508) {
          // Resolusi gambar valid
          if (this.DesignData.type != 'image/png' && this.DesignData.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.PreviewDesign = null
            this.data.design = null
            this.$refs.InputDesain.value = null
          }
          else {
            this.data.design = Date.now()+"_"+this.DesignData.name
            this.PreviewDesign = URL.createObjectURL(this.DesignData) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.DesignData = null
          return alert('Resolusi gambar tidak valid');
        }
      }
    },
    UploadDesain2(event){
      this.DesignData2 = event.target.files[0]
      const img = new Image();
      img.src = URL.createObjectURL(this.DesignData2);

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width === 4961 && height === 3508) {
          // Resolusi gambar valid
          if (this.DesignData2.type != 'image/png' && this.DesignData2.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.PreviewDesign2 = null
            this.data.design2 = null
            this.$refs.InputDesain2.value = null
          } else {
            this.data.design2 = Date.now()+"_"+this.DesignData2.name
            this.PreviewDesign2 = URL.createObjectURL(this.DesignData2) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.DesignData2 = null
          return alert('Resolusi gambar tidak valid');
        }
      };
    },
    UploadThumbnail(event){
      this.ThumbnailData = event.target.files[0]
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width === 360 && height === 480) {
          if (this.ThumbnailData.type != 'image/png' && this.ThumbnailData.type != 'image/jpeg' ) {
            alert('Format Thumbnail Harus jpg / jpeg / png')
            this.PreviewThumbnail = null
            this.data.thumbnail = null
            this.$refs.InputThumbnail.value = null
          } else {
            this.data.thumbnail = Date.now()+"_"+this.ThumbnailData.name
            this.PreviewThumbnail = URL.createObjectURL(this.ThumbnailData) 
          }
        } else {
          // Resolusi gambar tidak valid
          this.ThumbnailData = null
          return alert('Resolusi gambar tidak valid');
        }
      };

      img.src = URL.createObjectURL(this.ThumbnailData);
    },
    Simpan(){
      if(this.data.tags.length < 1){
        this.data.tags.push('#')
      }
      this.submit = true
      Object.values(this.data).forEach((value)=>{
        if(value == null || value == ''){
          this.submit = false
        }
      })
      if(this.submit){
        if(this.DesignData){
          const storageRef = ref(storage,"design/"+this.data.design)
          uploadBytes(storageRef, this.DesignData)
          deleteObject(ref(storage,"design/"+this.OldDesign))
        }
        if(this.DesignData2){
          const storageRef = ref(storage,"design/"+this.data.design2)
          uploadBytes(storageRef, this.DesignData2)
          deleteObject(ref(storage,"design/"+this.OldDesign2))
        }
        if(this.ThumbnailData){
          const storageRef = ref(storage,"thumbnail_design/"+this.data.thumbnail)
          uploadBytes(storageRef, this.ThumbnailData)
          deleteObject(ref(storage,"thumbnail_design/"+this.OldThumbnail))
        }
        const Ref = doc(db, "design_creation",this.id)
        this.data.updated_at = serverTimestamp()
        this.data.updated_by = this.$store.state.DashboardStore.email
        setDoc (Ref, this.data);
        alert("Karya Desain Berhasil Diupdate")
        this.$router.push('/admin/detailKaryaDesain/'+this.id)
      }else{
        this.error = []
        if (this.data.designer == null || this.data.designer == '') {
            this.error.push('\nDesigner Name')
        } 
        if (this.data.designer_id == null || this.data.designer_id == '') {
          this.error.push('\nStudent ID')
        } 
        if (this.data.semester == null || this.data.semester == '') {
          this.error.push('\nSemester')
        } 
        if (this.data.school_year == null || this.data.school_year == '') {
          this.error.push('\nSchool Year')
        } 
        if (this.data.code == null || this.data.code == '') {
          this.error.push('\nSubject')
        } 
        if (this.data.design == null || this.data.design == '') {
          this.error.push('\nDesign File 1')
        } 
        if (this.data.design2 == null || this.data.design2 == '') {
          this.error.push('\nDesign File 2')
        } 
        if (this.data.thumbnail == null || this.data.thumbnail == '') {
          this.error.push('\nThumbnail')
        }   
        alert('Harap isi kolom berikut :' + this.error)
      }
    },
    AddTags(){
      this.data.tags.push({value:''})
    },
  }
}
</script>