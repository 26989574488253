import { createApp } from 'vue';
import App from '@/App.vue';
import router from './routes';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/custom.css';


createApp(App).use(router).use(store).mount('#app');
