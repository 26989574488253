<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link :to="'/admin/detailKaryaDesain/'+id"  class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        ">
        
       
        Detail
      </router-link>
      <a 
        @click.prevent="Delete"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Delete
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { doc, deleteDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
const storage = getStorage()

export default {
  props:[
    'id',
    'designer',
    'thumbnail',
    'design'
  ],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    Delete(){
      if(confirm('Hapus Design Karya "'+this.designer+'"?')){
        deleteObject(ref(storage,"thumbnail_design/"+this.thumbnail))
        deleteObject(ref(storage,"design/"+this.design))
        const db = getFirestore()
        deleteDoc(doc(db, "design_creation", this.id));
        alert('Desain Karya "'+this.designer+'" Berhasil Dihapus')
        const KaryaDesainBaru = this.$store.state.DashboardStore.karyaDesain.filter(item=> item.id != this.id)
        this.$store.state.DashboardStore.karyaDesain =[]
        KaryaDesainBaru.forEach((doc)=>{
          const data = {}
          data.thumbnail = doc.thumbnail
          data.designer = doc.designer
          data.designer_id = doc.designer_id
          data.semester = doc.semester
          data.school_year = doc.school_year
          data.id = doc.id
          this.$store.commit('DataKaryaDesain',data)
          this.dropdownPopoverShow = false
        })
      }
      else{
        return false
      }

    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
