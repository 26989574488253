<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link :to="'/admin/socmed/edit/'+id"  class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        ">
        
       
        Edit
      </router-link>
      <a 
        @click.prevent="Delete"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Delete
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { doc, deleteDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
const storage = getStorage()

export default {
  props:[
    'id',
    'name',
    'icon_image',
  ],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    Delete(){
      if(confirm('Hapus Data Social Media "'+this.name+'"?')){
        deleteObject(ref(storage,"landing_page/"+this.icon_image))
        const db = getFirestore()
        deleteDoc(doc(db, "social_media", this.id));
        alert('Data Social Media "'+this.name+'" Berhasil Dihapus')
        const socmedNew = this.$store.state.MainStore.socmed.filter(item=> item.id != this.id)
        this.$store.state.MainStore.socmed =[]
        socmedNew.forEach((doc)=>{
          const data = {}
          data.name = doc.name
          data.icon = doc.icon
          data.url = doc.url
          data.id = doc.id
          this.$store.commit('setSocialMedia',data)
          this.dropdownPopoverShow = false
        })
      }
      else{
        return false
      }

    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
