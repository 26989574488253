const state = () => ({
  dashboardData: [],
  email: null,
  mataKuliah: [],
  karyaDesain: [],
  loggedIn : false
});

const mutations = {
  setDashboard(state, param) {
    state.dashboardData = param;
  },
  DataMataKuliah(state, data){
    state.mataKuliah.push(data)
  },
  DataKaryaDesain(state, data){
    state.karyaDesain.push(data)
  },
};
const getters = {
  loggedIn: state => state.loggedIn
};
const actions = {
  getDashboard(store, payload) {
    store.commit('setDashboard', payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};

