const state = () => ({
  landingPage: [],
  socmed: [],
  subject: [],
  content: [],
  suggestion: [],  
});

const mutations = {
  setLandingPage(state, param) {
    state.landingPage.push(param);
  },
  setSocialMedia(state, param) {
    state.socmed.push(param);
  },
  setSubject(state, param) {
    state.subject.push(param);
  },
  setContent(state, param) {
    state.content.push(param);
  },
  setSuggest(state, param) {
    state.suggestion.push(param);
  },
};

const actions = {
  getLandingPage(store, payload) {
    store.commit('setLandingPage', payload);
  },
};

export default {
  state,
  mutations,
  actions,
};
