<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardDetailMataKuliah />
    </div>
  </div>
</template>
<script>
import CardDetailMataKuliah from "@/components/Cards/CardDetailMataKuliah.vue";

export default {
  components: {
    CardDetailMataKuliah,
  },
};
</script>
