<template>
  <div>
    <index-navbar />
    <section class="header relative pt-8 items-center max-h-860-px section-pad">
      <div class="justify-center text-center flex flex-wrap md:mt-4">
        <div class="w-full md:w-6/12 px-12 md:px-4">
          <h2 class="font-semibold text-2xl home-title">{{ home[0].title }}</h2>
          <p class="text-lg leading-relaxed mt-6 mb-8 text-blueGray-500 description">
            {{ home[0].value }}
          </p>
        </div>
      </div>
    </section>
    <section class="section-pad">
      <div class="flex flex-wrap justify-center items-center">
        <div class="w-12/12 xl:w-4/12 px-4 mb-6 justify-center items-center flex" v-for="(item, index) in subject" :key="index">
          <router-link :to="'/collection/'+item.code">
            <div class="container">
              <img :id="item.thumbnail" :src="URL(item.thumbnail)" alt="">
              <div class="overlay overlay-home">
                <div class="text text-home">
                  <h1>{{ item.name+' ('+item.code+')' }}</h1>
                  <h1 class="caption mt-2 hidden md:block">{{ item.year }}</h1>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
import db from "@/firebase";
import { getDocs, collection } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
      home: [],
      collection: collection(db, "fix_parameter"),
      subject: this.$store.state.MainStore.subject,
    };
  },
  mounted() {
    this.getContent()
  },
  methods: {
    async fetchHome() {
      let snapShot = await getDocs(this.collection);
      let home = [];
      snapShot.forEach((doc) => {
        let data = doc.data();
        home.push(data);
      });
      // console.log("tests Data : ", home);
      this.home = home;
    },
    URL(image){
      const storage = getStorage()
      getDownloadURL(ref(storage, 'thumbnail/'+image)).then((url)=>{
        const img = document.getElementById(image);
        img.setAttribute('src', url);
      })
    },
    getContent() {
      const query = getDocs(collection(db, "course"))
      query.then((snapShots)=>{
        snapShots.docs.forEach((doc)=>{
          const data = {}
          data.thumbnail = doc.data().thumbnail
          data.name = doc.data().name
          data.code = doc.data().code
          data.year = doc.data().year
          this.$store.commit('setSubject',data)
        })
      })
    },
  },
  created() {
    this.fetchHome();
  },
  components: {
    IndexNavbar,
  },
};
</script>