<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardEditMataKuliah />
    </div>
  </div>
</template>
<script>
import CardEditMataKuliah from "@/components/Cards/CardEditMataKuliah.vue";

export default {
  components: {
    CardEditMataKuliah,
  },
};
</script>
